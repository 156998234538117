<template>
  <div class="popover-goods">
    <img   
      :src="product.goods_img"
      width="100%"
      height="100%"
    />
    <div
      v-show="!isSuggested && product.unit_discount != 0"
      class="unit-discount"
    >
      <div v-if="cartDiscountShow">
        <span>{{ (lang != 'il' ? '-' : '') + product.unit_discount + '%' }}</span>
        <span class="unit-discount-sale">{{
          (lang != 'il' ? '-' : '') + product.sale_discount + '%'
        }}</span>
      </div>
      <span v-else>{{ (lang != 'il' ? '-' : '') + product.unit_discount + '%' }}</span>
    </div>
    <div 
      v-if="modal"
      class="modal"
    >
      <span v-if="lengthStr">{{ lengthStr }}</span>
    </div>
  </div>
</template>

<script>
const { lang } = gbCommonInfo

export default {
  name: 'PopoverGoods',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    modal: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      lang,
    }
  },
  computed: {
    lengthStr() {
      if (this.length > 2 && this.length < 100) return `+${this.length}`
      if (this.length >= 100) return '99+'
      return ''
    },
    product() {
      return this.item?.product || {}
    },
    cartDiscountShow() {
      return this.product.sale_discount && this.product.sale_discount != this.product.unit_discount && this.item.promotionTypeIds.includes(3)
    },
    isSuggested() {
      if (typeof window == 'undefined') return false
      return window.gbCommonInfo.IS_SUGGESTED
    },
    isInversion() {
      return this.item?.is_inversion === 1
    },
  },
}
</script>

<style lang="less" scoped>
.popover-goods {
  position: relative;
  width: 38/37.5rem;
  height: 38/37.5rem;
  border-radius: 1px;
  margin-right: 4/37.5rem;
  .unit-discount {
    position: absolute;
    right: auto;
    text-align: center;
    font-size: 10px;
    color: #fff;
    direction: ltr /* rtl:ignore */;
    /* sh:begin */
    min-width: 2.75em;
    line-height: 1.35;
    top: 0;
    left: 0 /* rtl:ignore */;
    right: unset /* rtl:ignore */;
    background: @sui_color_discount;
    .unit-discount-sale {
      text-decoration: line-through;
      opacity: 0.8;
      display: block;
      /* rw:begin */
      font-family: Adieu-Regular, Adieu;
      font-weight: 400;
    }
  }
  .modal {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.70);
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
