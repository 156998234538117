var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-on-container j-add-on-container"},[(_vm.visible)?_c('div',{staticClass:"add-on-container__shade"}):_vm._e(),_vm._v(" "),(_vm.errorComponent)?[_c('NoNetworkEmpty',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorComponent == 'ErrorNetwork'),expression:"errorComponent == 'ErrorNetwork'"}],attrs:{"is-empty":true,"language":_vm.language},on:{"online-changed":_vm.handleOnlineStatus}}),_vm._v(" "),_c(_vm.errorComponent,_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isOnline),expression:"isOnline"}],tag:"component",on:{"close":_vm.handleClose}},'component',{
        visible: _vm.visible,
        language: _vm.language,
        statusMsg: _vm.statusMsg,
      },false))]:_c('s-drawer',_vm._b({ref:"add-on-container__drawer",staticClass:"container-drawer",class:{
      'half-radius': !_vm.isFullScreen,
    },attrs:{"visible":_vm.visible},on:{"update:visible":function($event){return _vm.handleClose()}}},'s-drawer',_vm.drawerCurrentConfig,false),[_c(_vm.componentNameFromType,_vm._b({ref:_vm.componentNameFromType,tag:"component",attrs:{"drawer-config":_vm.drawerConfig},on:{"close":_vm.handleClose}},'component',_vm.bindProps,false)),_vm._v(" "),_c('NoNetworkEmpty',{attrs:{"is-empty":!_vm.bindProps.type,"language":_vm.language},on:{"online-changed":_vm.handleOnlineStatus}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }