<template>
  <div class="free-shipping">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :flashsale-icon-style="flashsaleIconStyle"
      :config="config"
    >
      <template slot="header">
        <FreeShippingHeader
          :language="language"
          :promotion="promotion"
          :full-screen="isFullScreen"
          :time-stamp="timeStamp"
          :config="config"
        />
      </template>
      <template #footer="{ viewCart }">
        <FreeShippingFooter
          :language="language"
          :promotion="promotion"
          :config="config"
          @close="$emit('close')"
          @back="viewCart"
        />
      </template>
    </AddOnItem>  
  </div>
</template>

<script>
import { ADD_ON_COMP_MAP } from '../../utils/constants.js'
import { mapState } from 'vuex'
import { EventProgressUpdate } from '../../utils/event.js'
import { getFreeShippingChange } from '../../utils/index'

export default {
  name: 'TypeFreeShipping',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    FreeShippingHeader: () => import(/* webpackChunkName: "add_on_item" */'./FreeShippingHeader.vue'),
    FreeShippingFooter: () => import(/* webpackChunkName: "add_on_item" */'./FreeShippingFooter.vue'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ADD_ON_COMP_MAP,
      couponChange: 0,
      shippingFullTimes: 0,
      isFull: false,
      isShowedTip4: false, // 是否显示过动效4气泡文案
      isShowedTip5: false, // 是否显示过动效5气泡文案
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'isFullScreen', 'progressDataPromise']),
    anotherInfo() {
      const { addOnGoodsInfoList, isAmountType, diffPrice } = this.promotion
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      const { usdAmount } = diffPrice
      return {
        goodsPrice: isAmountType ? ((usdAmount || 0) * 100).toFixed(0) : '', // 凑单差额价格(美分)
        includeTspId: addOnGoodsInfoList.filter(item => item.config_type == 1).map(item =>  item.goods_pool_id).join(','),
        excludeTspId: addOnGoodsInfoList.filter(item => item.config_type == 2).map(item => item.goods_pool_id).join(','), 

        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    headerName() {
      return 'FreeShippingHeader'
    },
    footerName() {
      return 'FreeShippingFooter'
    },
    headerTitle() {
      return this.language.SHEIN_KEY_PWA_27302
    },
    flashsaleIconStyle() {
      return {
        '--bg': '#198055',
      }
    },
    timeStamp() {
      return (this.promotion?.countDownTimeStamp || 0) - Date.now() / 1000
    },
  },
  watch: {
    'promotion': {
      async handler(n, o) {
        const { isChange, isFreightStageChange } = getFreeShippingChange(n, o)
        if (n.isActivityStop) {
          this.progressDataPromise?.resolve?.({
            progressData: this.getProgressData(false),
            bubbleText: '',
            isChange: false,
          })
          return
        }
        if (isChange || isFreightStageChange) {
          this.couponChange = 1
          this.shippingFullTimes++
        }
        if (n.diffPrice?.amount == 0 && !this.isFull) {
          this.couponChange = 1
          this.shippingFullTimes++
          this.isFull = true
        }
        if (n.diffPrice?.amount == o.diffPrice?.amount && !isChange && !isFreightStageChange) {
          // @desc
          // 这里有问题，一次加车后 wather 执行多次
          // 达到档位后，当前 promise 一直处于 pending 状态
          // 导致其他业务代码依赖该状态无法执行
          if (this.promotion.progressRate === 100) {
            this.progressDataPromise?.resolve?.({
              isCompleted: true,
            })
          }
          return
        }
        console.log('free shipping change', isChange)
        this.progressDataPromise?.resolve?.({
          progressData: this.getProgressData(isChange),
          bubbleText: this.getBubbleText(isChange, isFreightStageChange),
          isChange,
        })
      },
      deep: true,
    }
  },
  mounted() {
    EventProgressUpdate.notifySync(this.getProgressData())
  },
  methods: {
    /**
     * 获取弹窗关闭载荷数据
     */
    getClosePayload() {
      return this.promotion
    },
  
    getProgressData(isChange = false) {
      const { progressRate } =  this.promotion
      return [
        {
          progressRate: progressRate, // 凑单进度
          ruleIndex: 0, // 当前档位
          activeRuleIndex: 0, // 正在凑单档位
          isChange, // 是否切换活动
        }
      ]
    },
    /**
     * 获取气泡文案
     * @param {Boolean} isChange 是否切换活动
     */
    getBubbleText(isChange, isFreightStageChange) {
      console.log('isChange', this.promotion)
      if (!this.promotion.tip) return ''
      // 运费活动
      if (this.promotion.activityType == 3) {
        if (isChange) {
          if (this.promotion.diffPrice?.amount == 0) {
            return ''
          }
          return this.language.SHEIN_KEY_PWA_27766
        }
        if (this.promotion.diffPrice?.amount == 0 || (isFreightStageChange && this.promotion.nowLevel > 0)) {
          if (this.isShowedTip5) return ''
          this.isShowedTip5 = true
          return this.language.SHEIN_KEY_PWA_27764
        }
        if (this.isShowedTip4) return ''
        this.isShowedTip4 = true
        return this.language.SHEIN_KEY_PWA_27762
      } else {
        if (isChange) {
          if (this.promotion.diffPrice?.amount == 0) {
            return ''
          }
          return this.language.SHEIN_KEY_PWA_27765
        }
        if (this.promotion.diffPrice?.amount == 0) {
          if (this.isShowedTip5) return ''
          this.isShowedTip5 = true
          return this.language.SHEIN_KEY_PWA_27763
        }
        if (this.isShowedTip4) return ''
        this.isShowedTip4 = true
        return this.language.SHEIN_KEY_PWA_27761
      }
    },
    getSaExtraData() {
      // 埋点上报的数据，具体含义请参考wikiId=1245676638
      return {
        promotion_code: this.promotion?.activityId || '',
        is_satisfied: this.promotion.diffPrice?.amount == 0 ? 1 : (this.promotion.nowLevel > 0 ? 2 : 0),
        coupon_change: this.couponChange,
        shipping_full_times: this.shippingFullTimes,
      }
    }
  },
}
</script>

<style lang="less" scoped>
.free-shipping {
  height: 100%;
  background: transparent;
}
</style>
