<template>
  <SBusinessCart
    ref="cartRef"
    size="80"
    :type="type"
    :state="state"
    :promotion-id="promotionId"
    :base-config="baseConfig"
    :locals="locals"
    :ui-config="{ 
      isShowNewUserPrice: false,
      autoUseCoupon: false,
      autoUsePoints: false,
      allowDeleteGift: 1,
      repickAddon: 1,
    }"
    :bottom-placeholder="true"
    :user-local-size-country="userLocalSizeCountry"
    @cartUpdated="onCartUpdated"
    @quick-add="handleQuickAdd"
    @open="isOpened = true"
    @close="isOpened = false"
  >
    <template #bottom>
      <SPopover
        v-model="popoverShow"
        class="business-cart-popover"
        trigger-type="user"
        show-close-icon
        :append-to-body="false"
        :prop-style="{ width: '90vw'}"
        placemen="top"
      >
        <div
          v-expose="{
            id: '1-8-4-21',
            data: { state: state }
          }"
          v-tap="{
            id: '1-8-4-20',
            data: { state: state }
          }"
          class="unchecked-items" 
          @click="openBusinessCart(unCheckedGoodsShowList[0])"
        >
          <div class="goods">
            <PopoverGoods
              v-for="(item, i) in unCheckedGoodsShowList"
              :key="i"
              :item="item"
              :modal="i === 1"
              :length="unCheckedGoodsList.length"
            />
          </div>
          <div class="unchecked-tips">
            {{ language.SHEIN_KEY_PWA_30093 }}
          </div>
          <div class="pick-btn">
            {{ language.SHEIN_KEY_PWA_15830 }}
          </div>
        </div>
        <template slot="reference">
          <div 
            class="business-cart-foot" 
            :style="{
              ...styleConfig,
              'background-color': isOpened ? '#f5f5f5' : '#fff',
            }"
          >
            <section class="top">
              <p 
                class="add-on-tips" 
                v-html="addOnTips"
              >
              </p>
              <ProgressBar
                v-if="showProgress"
                class="progress"
                transparent
                animation
              />
            </section>
            <section class="bottom">
              <div class="left">
                <SBadge 
                  type="danger"
                  :value="cartCheckedNum"
                  :max="99"
                  :badge-style="{
                    bottom: '72%',
                    [GB_cssRight ? 'right' : 'left']: '90%',
                  }"
                >
                  <img
                    v-expose="{
                      id: '1-8-4-19',
                      data: { state: state }
                    }"
                    v-tap="{
                      id: '1-8-4-18',
                      data: { state: state }
                    }"
                    class="j-add-on-business-cart"
                    src="https://img.ltwebstatic.com/images3_acp/2024/03/15/a4/1710507571e64bf0c57e6ee9e2f3321242b1cb00f3.png"
                    width="60px"
                    @click="openBusinessCart"
                  />
                </SBadge>
                <div class="price">
                  <PriceCountTo 
                    class="price-total" 
                    :class="{
                      'is-discount': isDiscount
                    }"
                    :amount="totalPrice.amount"
                  />
                  <p 
                    v-if="showDiscountPrice"
                    class="price-saved"
                  >
                    <span>{{ language.SHEIN_KEY_PWA_29458 }} </span>
                    <b>
                      <PriceCountTo 
                        :amount="totalDiscountPrice.amount"
                      />
                    </b>
                  </p>
                </div>
              </div>
              <div class="right">
                <s-button
                  :type="['H80PX', 'primary']"
                  class="go-checkout"
                  :class="{
                    'scan-lights': addOnStatus > 0,
                  }"
                  @click="$emit('checkoutClick')"
                >
                  {{ language.SHEIN_KEY_PWA_27304 }}
                </s-button>
                <IncentivePoint
                  class="incentive-point"
                  :tags="tags"
                  :bubble="bubble"
                />
              </div>
            </section>
          </div>
        </template>
      </SPopover>
    </template>
  </SBusinessCart>
</template>

<script>
import { SBusinessCart } from '@shein/business-cart'
import schttp from 'public/src/services/schttp'
import { Popover as SPopover, Badge as SBadge } from '@shein/sui-mobile'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { IS_RW, lang, currency, appLanguage, GB_cssRight, SiteUID, currencies } = gbCommonInfo
import { openQuick4BCart } from 'public/src/pages/common/utils/quickAdd4BusinessCart.js'
import PopoverGoods from './PopoverGoods.vue'
import ProgressBar from 'public/src/pages/common/addOnItem/comps/base/ProgressBar.vue'
import PriceCountTo from 'public/src/pages/common/addOnItem/comps/base/PriceCountTo.vue'
import { mapState } from 'vuex'
import { EventUpdateBusinessCart, EventViewTap } from '../../utils/event.js'
import incentivePointForPromo from 'public/src/pages/common/addOnItem/mixins/incentivePointForPromo.js'

const _getProgressShow = ({ abtParam, addOnStatus }) => {
  if (abtParam === 'B') { return addOnStatus === 0 }
  if (abtParam === 'C') { return false }
  return true
}

const _getStyleConfig = ({ abtParam, addOnStatus }) => {
  if (abtParam === 'B' && addOnStatus > 0) {
    return {
      '--top-bg': 'url(https://img.ltwebstatic.com/images3_acp/2024/03/15/81/171050752991547ff45076a2d0685a321bb4deb987.png)',
      '--tips-color': '#FFF',
      '--tips-em-color': '#FFF',
    }
  }
  if (abtParam === 'C') {
    return addOnStatus > 0 ? {
      '--top-bg': 'url(https://img.ltwebstatic.com/images3_acp/2024/03/15/81/171050752991547ff45076a2d0685a321bb4deb987.png)',
      '--tips-color': '#FAE9D8',
      '--tips-em-color': '#FFF',
    } : {
      '--top-bg': 'url(https://img.ltwebstatic.com/images3_acp/2024/03/15/54/1710507546d3d8b779f3d3e1999ac919f7ff31e48e.png)',
      '--tips-color': '#000',
      '--tips-em-color': '#FA6338', 
    }
  }
  return {
    '--top-bg': 'linear-gradient(180deg, rgba(255, 255, 255, 0.79) 0%, #FFF 100%)',
    '--tips-color': '#000',
    '--tips-em-color': '#FA6338',
  }
}

export default {
  name: 'BusinessCartFoot',
  components: {
    SPopover,
    SBadge,
    SBusinessCart,
    PopoverGoods,
    PriceCountTo,
    ProgressBar,
  },
  mixins: [incentivePointForPromo],
  props: {
    type: {
      type: String,
      default: '',
    },
    promotionId: {
      type: [Number, String],
      required: true,
    },
    state: {
      type: String,
      default: '',
    },
    addOnStatus: { // 凑单状态
      type: Number,
      default: null, //  0:全部未满足; 1:全部满足; 2:部分满足
    },
    addOnTips: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      baseConfig: {
        project: 'PWA',
        schttp,
        abtservice,
        daEventCenter,
        daEventABC: '1-8-10',
      },
      locals: {
        IS_RW,
        lang,
        currency,
        appLanguage, 
        GB_cssRight,
        SiteUID,
        currencies,
      },
      cartInfo: {},
      groups: [],
      isOpened: false,
      popoverShow: false,
      isFirst: false, // 是否首次获取到非标购物车数据
      userLocalSizeCountry: '',
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language']),
    cartCheckedNum () {
      const checkedItems = this.cartInfo?.carts?.filter(item => item.is_checked == 1) || []
      return checkedItems.reduce((acc, cur) => {
        return acc + cur.quantity
      }, 0)
    },
    totalPrice() {
      if (this.isSuggested) return this.cartInfo?.total_price_before_coupon || {}
      return this.cartInfo?.totalPrice || {}
    },
    totalDiscountPrice () {
      return this.cartInfo?.total_discount_price || {}
    },
    showProgress() {
      const { cartadd_coupon_bottom_style } = this.abtInfo?.CartaddCouponBottomStyle?.param || {}
      return _getProgressShow({ abtParam: cartadd_coupon_bottom_style, addOnStatus: this.addOnStatus })
    },
    styleConfig() {
      const { cartadd_coupon_bottom_style } = this.abtInfo?.CartaddCouponBottomStyle?.param || {}
      return _getStyleConfig({ abtParam: cartadd_coupon_bottom_style, addOnStatus: this.addOnStatus })
    },
    unCheckedGoodsList() {
      return this.groups[0]?.list?.filter((v) => !v.is_checked) || []
    },
    unCheckedGoodsShowList() {
      return this.unCheckedGoodsList.slice(0, 2)
    },
    isSuggested() {
      if (typeof window == 'undefined') return false
      return window.gbCommonInfo.IS_SUGGESTED
    },
    showDiscountPrice() {
      return !this.isSuggested && this.totalDiscountPrice.amount > 0
    },
    isDiscount() {
      if (this.isSuggested) return false
      return this.totalDiscountPrice.amount > 0
    },
  },
  created() {
    this.userLocalSizeCountry = this.getUserLocalSizeCountry()
  },
  methods: {
    getUserLocalSizeCountry() {
      return typeof window === 'undefined' ? '' : (window.localStorage.getItem('last_select_country') || '')
    },
    openBusinessCart(cartItem) { // 打开非标购物车
      EventViewTap.notify({ type: 'CLICK_BUSINESS_CART_ENTRY' })
      this.closePopover()
      this.userLocalSizeCountry = this.getUserLocalSizeCountry()
      this.$refs.cartRef?.open?.({ id: cartItem?.id })
    },
    refetchCarts() { // 刷新非标购物车
      this.closePopover() // 关闭气泡
      this.$refs.cartRef?.refetchData?.()
    },
    onCartUpdated(info) {
      this.cartInfo = info?.cartInfo || {} // 更新底部车数据
      this.groups = info?.groups || []

      // 首次获取非标车，判断是否弹出povover
      if (!this.isFirst) {
        this.isFirst = true
        this.handlePopover()
      }

      this.$emit('cartUpdated', info)
      EventUpdateBusinessCart.notify(info) // 通知外层调用方
    },
    handlePopover() {
      // 未满足最高档且有未勾选商品
      if (this.addOnStatus !== 1 && this.unCheckedGoodsShowList.length) {
        this.popoverShow = true
        this.timer = setTimeout(() => {
          this.closePopover()
        }, 5000)
      }
    },
    closePopover() {
      this.popoverShow = false
      clearTimeout(this.timer)
    },
    async handleQuickAdd(params) {
      await openQuick4BCart(params, this.$quickAdd, daEventCenter)
    }
  },
}
</script>

<style lang="less" scoped>
.business-cart-foot {
  position: relative;
  width: 100%;
}
.top {
  min-height: 40/37.5rem;
  padding: 6/37.5rem 12/37.5rem;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    background: var(--top-bg);
    background-size: cover;
    left: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0.9;
  }
  .add-on-tips {
    line-height: 14/37.5rem;
    color: var(--tips-color);
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 1;
    /deep/ em {
      font-weight: bold;
      color: var(--tips-em-color);
    }
  }
  .progress {
    margin-top: 4/37.5rem;
    z-index: 1;
  }
}
.bottom {
  background: #fff;
  padding: 6/37.5rem 12/37.5rem;
  display: flex;
  align-items: center;
  .left {
    flex-grow: 1;
    margin-right: 12/37.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    .price {
      margin-left: 6/37.5rem;
      overflow: hidden;
    }
  }
  .right {
    position: relative;
    .go-checkout {
      padding: 0 12/37.5rem;
    } 
    .incentive-point {
      position: absolute;
      right: 0;
      bottom: calc(100% - 0.05333333rem);
      z-index: @zindex-hack;
    }
    .free-ship {
      .font-dpr(18px);
      line-height: 12px;
      position: absolute;
      height: 14/37.5rem;
      right: 0;
      top: -10/37.5rem;;
      padding: 0 4/37.5rem;
      border-radius: 20/75rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border: 1px solid #fff;
      background: @sui_color_safety;
    }
    .free-ico {
      margin-right: 2/37.5rem;
      transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
    }
  }

  .price-total {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.is-discount {
      color: @sui_color_promo;
    }
    /deep/ * {
      .price-currency, .price-decimal {
        font-size: 10px;
      }
      .price-amount {
        font-size: 17px;
      }
    }
  }
  .price-saved {
    font-size: 10px;
    line-height: 12px;
    color: @sui_color_gray_dark3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.business-cart-popover {
  display: inline-block; // 解决spopover在safari的定位问题
  width: 100%;
}
.business-cart-popover /deep/ .S-popover__main-content {
  padding: 12/37.5rem;
  .unchecked-items {
    display: flex;
    align-items: center
  }
  .unchecked-tips {
    font-size: 11px;
    padding: 0 8/37.5rem 0 4/37.5rem;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .pick-btn {
    font-size: 12px;
    padding: 2/37.5rem 14/37.5rem;
    border-radius: 12px;
    color: #fff;
    background-color: @sui_color_discount;
  }
  .goods {
    display: flex;
  }
}
</style>
